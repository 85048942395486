/**
 *  submenu toggle
 *  allow parents with submenu to be linked on a page on mobile
 */

const submenuToggle = function () {
  const $menuItems = $('#offCanvas li.is-accordion-submenu-parent');

  // append a button after every link
  $menuItems.each((_, item) => {
    const href = $(item).find('> a').attr('href');
    const label = $(item).find('> a').text();
    isValidHttpUrl(href) && $(item).children('a').after(`<button class="button-link">${label}</button>`);
  });

  $('.button-link').on('click', clickHandler);

  function clickHandler() {
    const href = $(this).siblings('a').attr('href');
    isValidHttpUrl(href) && window.location.replace(href);
  }

  // https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
  function isValidHttpUrl(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }
};

export { submenuToggle };
