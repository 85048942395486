/**
 *  Webcams
 *  webcams section
 */

import 'jquery.kinetic';

const webcamsDisplayer = function () {
  // console.log('webcams initiate');

  // Sareis, ..
  panoramaHandler();
  // Täli, ..
  videoHandler();

  function panoramaHandler() {
    const $panorama = $('.webcam-widget-panorama .webcam-widget__inner'); 
    if ($panorama.length === 0) return;

    $panorama.kinetic({
      moved: () => $('.drag-hint').fadeOut(),
    });
  }

  function videoHandler() {
    const refreshBtn = document.querySelector(
      '.webcam-widget-video .icon-refresh'
    );
    if (!refreshBtn) return;

    refreshBtn.addEventListener('click', refreshHandler);

    function refreshHandler(e) {
      e.preventDefault();

      const video = document.querySelector('video');
      const source = video.querySelector('source');
      // console.log(source);

      const srcCurrent = source.getAttribute('src');
      const typeCurrent = source.getAttribute('type');

      source.remove();

      const sourceNew = document.createElement('source');
      sourceNew.setAttribute('src', srcCurrent);
      sourceNew.setAttribute('type', typeCurrent);

      video.innerHTML = '';
      video.appendChild(sourceNew);
      video.play();
    }
  }
};

export { webcamsDisplayer };
