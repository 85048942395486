import View from './View';

class weatherForecastCardView extends View {
  _parentElement = '';

  _generateMarkup() {
    return `
      <div class="weather-card">
        <div class="weather-card__title">
          <div class="weather-card__date">${this._data.date}</div>
          <h1 class="weather-card__day">${this._data.day}</h1>
        </div>
        <div class="weather-card__icon">
          <i class="fa fa-custom fa-weather fa-weather__${this._data.weatherSymbol} full"></i>
        </div>
        <div class="weather-card__info">
          <div class="weather-card__temprature">
            <span class="min">
              min.<strong>${this._data.tempMin}</strong>
            </span>
            <span class="max">
              max.<strong>${this._data.tempMax}</strong>
            </span>
          </div>
          <div class="weather-card__rain">
            <i class="fa fa-custom fa-weather fa-weather__18"></i>
            <span>${this._data.rain}</span>
          </div>
          <div class="weather-card__wind">
            <i class="fa fa-custom fa-weather fa-weather__17"></i>
            <span>${this._data.wind}</span>
          </div>
        </div>
      </div>
    `;
  }
}

export default new weatherForecastCardView();
