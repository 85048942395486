/**
 *  View: parent class
 *  Render the received object to the DOM
 */

class View {
  _data;
  _oevrride;

  /**
   * Render the received object to the DOM
   */
  render(data, render = true, override = true) {
    this._data = data;
    // console.log(this._data);
    this._oevrride = override;

    if (!data || (Array.isArray(data) && data.length === 0))
      return this.renderError();

    const markup = this._generateMarkup();
    // console.log(markup);

    if (!render) return markup;

    this._clear();

    this._parentElement.insertAdjacentHTML('afterbegin', markup);
  }

  _clear() {
    this._parentElement.innerHTML = '';
  }

  renderError(message = this._errorMessage) {
    const markup = `
      <div class="error">
        ${message}
      </div>
    `;

    this._clear();
    this._parentElement.insertAdjacentHTML('afterbegin', markup);
  }
}

export default View;
