import View from './View';

class topbarWeatherForecastLinkView extends View {
  _parentElement = document.querySelector('#topbar .weather-forecast a');
  _errorMessage = 'Error!';
  _message = '';

  _generateMarkup() {
    // console.log(this._data);

    // handle errors returns by php
    if (!this._data) this.renderError();

    return `
      <i class="fa fa-custom fa-weather fa-weather__${this._data.days[0].weatherSymbol}"></i>
      <div class="weather-forecast-content">
        <span>${this._data.days[0].tempMin}</span>
        <span>${this._data.days[0].tempMax}</span>
      </div>
    `;
  }

  renderError(message = this._errorMessage) {
    // console.log(this._errorMessage);

    this._parentElement.setAttribute('title', this._errorMessage);
    this._parentElement.classList.add('error');
  }
}

export default new topbarWeatherForecastLinkView();
