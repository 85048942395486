import View from './View';
import weatherForecastCardView from './weatherForecastCardView.js';

class weatherForecastWidgetView extends View {
  _parentElement = document.querySelector('.weather-forecast-widget');
  _errorMessage = 'Error!';
  _message = '';

  _generateMarkup() {
    //console.log(this._data);

    // handle errors returns by php
    if (!this._data) this.renderError();

    return `
      <div class="row">
        ${this._data.days.map(this._generateMarkupCard).join('')}
      </div>
    `;
  }

  _generateMarkupCard(day) {
    return `
    <div class="small-12 medium-3 columns">
      ${weatherForecastCardView.render(day, false)}
    </div>
    `;
  }
}

export default new weatherForecastWidgetView();
