$(function () {
    $(document).foundation();
    var $offCanvas = $("#offCanvas");
    var $body = $("body");

    checkScroll();
    initializeLightbox();
    animateThings();

    if (window.location.hash) {
        var fakeObject = $("<a href='" + window.location.hash + "'></a>");
        scrollToAnchor(fakeObject[0], false);
    }
    $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').click(function (event) {
        scrollToAnchor(this, event);
    });


    $(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {
        if (newSize != 'small') {
            $offCanvas.foundation('close');
        }
    });

    $(window).scroll(function () {
        checkScroll();
    });

    $(window).load(function () {
        setTimeout(function () {
            $(window).trigger("resize"); //fix for parallaxic bug
        }, 600);

    });

    function checkScroll() {
        if ($(window).scrollTop() > 100) {
            $body.addClass("page-is-scrolled");
        } else {
            $body.removeClass("page-is-scrolled");
        }
        if ($(window).scrollTop() > 600) {
            $("#scroll-to-top").fadeIn();
        } else {
            $("#scroll-to-top").fadeOut();
        }
    };

    function scrollToAnchor(link, event) {

        if ($offCanvas) {
            $offCanvas.foundation('close');
        }

        var menuOffset = parseInt($("#header-wrapper").outerHeight()) + 40;

        if ($body.hasClass("is-logged-in")) {
            menuOffset = menuOffset + 48;
        }

        if (
            location.pathname.replace(/^\//, '') == link.pathname.replace(/^\//, '')
            &&
            location.hostname == link.hostname
        ) {
            // Figure out element to scroll to
            var target = $(link.hash);
            target = target.length ? target : $('[name=' + link.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                if (event) {
                    event.preventDefault();
                }
                var $accordionToOpen = target.closest(".accordion-item");
                if ($accordionToOpen.length > 0) {
                    $accordionToOpen.parent().foundation('down', $accordionToOpen.find(".accordion-content"), true);
                }

                $('html, body').animate({
                    scrollTop: target.offset().top - menuOffset //because of fixed header
                }, 1000, function () {
                    //callback
                });

            }
        }
    }

    function initializeLightbox() {
        $('a[data-lightbox]').magnificPopup({
            type: 'image',
            gallery: {
                enabled: true
            },
            removalDelay: 300,
            mainClass: 'mfp-fade'
        });
    }

    function animateThings() {
        $('[sw-theme-data-animation]').waypoint({
            handler: function () {
                var animationClass = $(this.element).attr("sw-theme-data-animation");

                $(this.element).addClass("animated visible " + animationClass);
            },
            offset: '80%'
        });
    }
});


