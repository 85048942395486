/**
 *  Activities Carousel
 */
import 'owl.carousel';

const activitiesCarousel = function () {
  const $carousel = $('.activities-carousel');
  if ($carousel.length === 0) return;

  // initiate owl
  $carousel.owlCarousel({
    items: 3,
    margin: 30,
    loop: true,
    dots: false,
    nav: true,
    lazyLoad: true,
    responsive: {
      0: { items: 1 },
      360: { items: 1 },
      480: { items: 2 },
      830: { items: 3 }
    },
  });
};

export { activitiesCarousel };
