/**
 *  Home Slider
 */
import 'owl.carousel';

const headerSlider = function () {
  const $carousel = $('.header-slider');
  if ($carousel.length === 0) return;

  const time = 8000; // time in mseconds
  let percentTime = 0;
  let isPause = false;
  let tick;
  let $bar;
  const dotsBleedingDegree = 1; // bleeding degree

  const dotColor = 'white';
  const dotColorActive = '#536b81';

  // set isPaused
  $carousel.on('mouseover', () => (isPause = true));
  $carousel.on('mouseout', () => (isPause = false));

  // initiating owl
  $carousel.owlCarousel({
    items: 1,
    margin: 0,
    loop: true,
    dots: false,
    nav: false,
    lazyLoad: true,
    onInitialized: initiatingCarousel,
    onChange: fillIt,
    onChanged: processingBar,
  });

  function initiatingCarousel(e) {
    // console.log('initiating carousel');
    processingBar(e);
  }

  function processingBar(e) {
    // console.log('processing bar');
    // console.log(e);

    // get active bar
    $bar = $carousel.find('.owl-dot.active span');
    let $activeFound = false;
    $carousel.find('.owl-dot').each(function () {
      if (this.classList.contains('active')) {
        $bar = $(this).find('span');
        $activeFound = true;
      } else if ($activeFound) {
        $(this).find('span').css('background', dotColor);
      } else {
        $(this).find('span').css('background', dotColorActive);
      }
    });

    isPause = false;
    percentTime = 0;
    tick = setInterval(interval, 10, time);
  }

  function interval(time) {
    if (isPause) return;

    percentTime += 1000 / time; // console.log(percentTime);

    // tweak the gradient
    $bar.css(
      'background',
      `linear-gradient(
      90deg,
      ${dotColorActive} 0%,
      ${dotColorActive} ${percentTime - dotsBleedingDegree}%,
      ${dotColor} ${percentTime + dotsBleedingDegree}%,
      ${dotColor} 100%
    )`
    );

    if (percentTime >= 100) {
      clearTimeout(tick);
      $bar.css('background', dotColor);
      $carousel.trigger('next.owl.carousel');
    }
  }

  function moved() {
    // console.log('moved');
  }

  function fillIt() {
    // console.log('fillIt');
    clearTimeout(tick);
  }
};

export { headerSlider };
