/**
 *  bergbahnen theme JS Bundle
 */

import $ from 'jquery';
import './app.js';
import { activitiesCarousel } from './src/activitiesCarousel';
import { headerSlider } from './src/headerSlider';
import { topbarDropdown } from './src/topbarDropdown';
import { responsiveTables } from './src/responsiveTables';
import { weatherDataDisplayer } from './src/weatherDataDisplayer';
import { webcamsDisplayer } from './src/webcamsDisplayer';
import { webcamsDateDisplayer } from './src/webcamsDateDisplayer';
import { submenuToggle } from './src/submenuToggle';
import { fullscreenWebcam } from './src/fullscreenWebcam';

document.addEventListener('DOMContentLoaded', function () {
  const version = '1.0.0';
  console.log('version ', version);

  // initiating activities carousel
  activitiesCarousel();

  // initiating header slider
  headerSlider();

  // initiating responsive tables
  responsiveTables();

  // initiating topbar dropdown
  topbarDropdown();

  // initiating weather data displayer: calling API/rendeing:
  // 1: topbar weather forecast, 2: topbar snow depth, 3: weather forecast dropdown, 4: pages that display weather forecasts
  weatherDataDisplayer();

  // initiating webcams displayer related scripts (refresher, kinetic plugin, ...)
  webcamsDisplayer();

  // initiating webcams date displayer: date displayed image is taken
  webcamsDateDisplayer();

  // initiating submenu toggle (allow parents with submenu to be linked on a page on mobile)
  submenuToggle();

  // initiating fullscreen webcam (panorama pages)
  fullscreenWebcam();
});
