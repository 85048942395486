/**
 *  Webcams
 *  date displayer: date displayed image is taken
 */

const webcamsDateDisplayer = function () {
  const $webcamWidgetDate = $('.webcam-widget .widget-date');
  if ($webcamWidgetDate.length === 0) return;

  let url = $webcamWidgetDate.data('date-api-url'); // provided on `widget`
  if(!url) return;

  const $webcamWidgetDateIcon = $('i', $webcamWidgetDate);
  beatIt(true);

  const jqXHR = $.get(url, function (data) {
    // console.log('data loaded');
  });

  jqXHR.always(() => beatIt(false));

  jqXHR.fail(() => {
    // fill content with api not responsing
  });

  jqXHR.done(() => {
    const { responseJSON } = jqXHR;

    const date = responseJSON.success ? responseJSON.data.data.timestamp : responseJSON.msg_error;
    $webcamWidgetDate.find('span').hide().text(date).fadeIn('slow');
  });


  /** helpers */
  // preloader
  function beatIt(state) {
    $webcamWidgetDateIcon.toggleClass('icon-beat', state);
  }
};

export { webcamsDateDisplayer };
