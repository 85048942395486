/**
 *  Weather
 *  calling API/rendeing topbar icon/info, dropdown, and pages that display weather cards
 */
import topbarWeatherForecastLinkView from './views/topbarWeatherForecastLinkView';
import topbarWeatherSnowDepthLinkView from './views/topbarWeatherSnowDepthLinkView';
import weatherForecastDropdownView from './views/weatherForecastDropdownView';
import weatherForecastWidgetView from './views/weatherForecastWidgetView';

const weatherDataDisplayer = function () {
  const $topbar = $('#topbar');
  if ($topbar.length < 1) return;

  const url = $topbar.data('weather-api-url'); // provided on `topbar.php`
  if(!url) return;

  const $topbarWeatherForecastLink = $('.weather-forecast a', $topbar);
  const $topbarWeatherSnowDepthLink = $('.weather-snow-depth a', $topbar);
  const $weatherForecastDropdown = $('#topbar-dropdown-weather-forecast'); // weather forecast dropdown
  const $weatherForecastWidget = $('.weather-forecast-widget'); // any element in page supposed to display weather cards

  const $topbarWeatherForecastLinkIcon = $('i', $topbarWeatherForecastLink);
  const $topbarWeatherSnowDepthLinkIcon = $('i', $topbarWeatherSnowDepthLink);
  beatIt(true);

  const jqXHR = $.get(url, function (data) {
    // console.log('data loaded');
  });

  jqXHR.always(() => beatIt(false));

  jqXHR.fail(() => {
    // fill content with api not responsing
  });

  jqXHR.done(() => {
    const { responseJSON } = jqXHR;
    // console.log('done: ', responseJSON);

    /** render it to different sections of the dom: */
    // weather forecast on topbar
    if ($topbarWeatherForecastLink.length != 0)
      topbarWeatherForecastLinkView.render(responseJSON.data.forecast);

    // weather snow depth on topbar
    if ($topbarWeatherSnowDepthLink.length != 0)
      topbarWeatherSnowDepthLinkView.render(responseJSON.data.snowDepth);

    // // weather forecast cards on dropdown
    if ($weatherForecastDropdown.length != 0)
      weatherForecastDropdownView.render(responseJSON.data.forecast);

    // // weather forecast widget (cards) on any page contains a div.weather-forecast-widget
    if ($weatherForecastWidget.length != 0)
      weatherForecastWidgetView.render(responseJSON.data.forecast);
  });

  /** helpers */
  // preloader
  function beatIt(state) {
    $topbarWeatherForecastLinkIcon.toggleClass('icon-beat', state);
    $topbarWeatherSnowDepthLinkIcon.toggleClass('icon-beat', state);
  }
};

export { weatherDataDisplayer };
