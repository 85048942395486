/**
 *  Topbar Dropdown
 */

const topbarDropdown = function () {
  const $body = $('body');
  const $dropdowns = $('[id^="topbar-dropdown-"]'); //console.log($dropdowns);
  const $closeIcons = $dropdowns.find('i.fa-times-droppane'); // console.log($closeIcons);
  let $activeDropdown = false;

  // on show
  $dropdowns.on('show.zf.dropdown', function () {
    // console.log('dp display');

    $body.addClass('no-scroll');
    $activeDropdown = $(this);
    beatIt(true);

    // const hash = window.location.hash.substr(1); // hash not working for the first time
    // console.log(`current hash: ${hash}`);

    // console.log($activeDropdown.attr('id'));
    switch ($activeDropdown.attr('id')) {
      case 'topbar-dropdown-weather-forecast':
        weatherForecastHandler();
        break;
      case 'topbar-dropdown-weather-snow-depth':
        schneehohenHandler();
        break;
      case 'topbar-dropdown-facilities':
        anlagenHandler();
        break;
    }
  });

  // on hide
  $dropdowns.on('hide.zf.dropdown', function () {
    // console.log('dp hide');
    $body.removeClass('no-scroll');

    beatIt(false);
    fillContent(); // clear the panel
    $activeDropdown = false;
  });

  /** Event handlers */
  const weatherForecastHandler = function () {
    // console.log('weather Handler');
    beatIt(false);

    const $content = $('#dropdown-weather-forecast-content').html();
    // console.log($content);
    fillContent($content);
  };

  const schneehohenHandler = function () {
    // console.log('schneehohen Handler');
    const url = 'https://sdds4.intermaps.com/malbun/web_malbun.asp?lang=de';

    const iframe = document.createElement('iframe');
    iframe.onload = () => beatIt(false);
    iframe.src = url;
    appendContent(iframe);
  };

  const anlagenHandler = function () {
    // console.log('anlagen Handler');
    const url = 'https://winter.intermaps.com/malbun';

    const iframe = document.createElement('iframe');
    iframe.onload = () => beatIt(false);
    iframe.src = url;
    appendContent(iframe);
  };

  // close panel
  $closeIcons.on('click', function () {
    $(this).closest('[id^="topbar-dropdown-"]').foundation('close');
  });

  /** helpers */
  const fillContent = function (content = '') {
    $activeDropdown.find('.content').html(content);
  };

  const appendContent = function (content = '') {
    $activeDropdown.find('.content').append(content);
  };

  // preloader
  const beatIt = function (state) {
    $activeDropdown
      .find('.dropdown-pane-inner')
      .toggleClass('dropdown-beat', state);
  };
};

export { topbarDropdown };
