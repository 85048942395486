/**
 *  Responsive Tables
 *  Set data-label attribute for tbody cells predicated upon thead cells
 */

const responsiveTables = function () {
  const tables = document.querySelectorAll('table.stack');

  tables.forEach((table) => {
    const headerCells = table.querySelectorAll('thead th');

    headerCells.forEach((_headerCell, i) => {
      table
        .querySelectorAll(`tbody tr td:nth-child(${i + 1})`)
        .forEach((cell) => (cell.dataset.label = headerCells[i].innerText.trim()));
    });
  });
};

export { responsiveTables };
