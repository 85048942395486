/**
 *  Fullscreen Webcams
 *  panorama pages
 */

const fullscreenWebcam = function () {
  const btn = document.querySelector('.button-fullscreen');
  if (!btn) return;

  let fullscreenMode = false;

  btn.addEventListener('click', handleClick);
  document.addEventListener('fullscreenchange', handleFullscreenchange);

  function handleClick(e) {
    //this.querySelector('i').classList.toggle('active')
    this.querySelector('i').classList.contains('active')
      ? closeFullscreen()
      : openFullscreen();
  }

  function handleFullscreenchange(e) {
    fullscreenMode =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement;

    btn.querySelector('i').classList.toggle('active', fullscreenMode != null);
  }

  // https://www.w3schools.com/howto/howto_js_fullscreen.asp
  /* View in fullscreen */
  var elem = document.documentElement;
  function openFullscreen() {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  function closeFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }
};

export { fullscreenWebcam };
