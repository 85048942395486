import View from './View';
import weatherForecastCardView from './weatherForecastCardView.js';

class weatherForecastDropdownView extends View {
  _parentElement = document.querySelector('#dropdown-weather-forecast-content');
  _errorMessage = 'Error!';
  _message = '';

  _generateMarkup() {
    //console.log(this._data);

    // handle errors returns by php
    if (!this._data) this.renderError();

    return `
      <div class="row">
        ${this._data.days.slice(0, 3).map(this._generateMarkupCard).join('')}
      </div>
      <div class="row">
        <div class="small-12 columns">
          <div class="weather-card">
            <a href="${
              this._data.detailUrl
            }" target="_blank" class="underlined">${this._data.detailUrlTitle}</a>
          </div>
        </div>
      </div>
    `;
  }

  _generateMarkupCard(day) {
    return `
    <div class="small-12 medium-4 columns">
      ${weatherForecastCardView.render(day, false)}
    </div>
    `;
  }
}

export default new weatherForecastDropdownView();
